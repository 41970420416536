import Layout from "../../components/layout/Layout";
import DynamicComponent from "../../components/DynamicComponent";

import Storyblok, { defaultStoryblokOptions, useStoryblok } from "../../utils/storyblok";
import { getAllPageData, getPpm365Updates } from "../../utils/ppmApi";
import { useState } from "react";
import { NextSeo } from "next-seo";
import { getPartnerLandingData } from "../../utils/ppm365Api";
import { PartnerLandingContext } from "../../components/contexts/ppm365";

interface ComponentProps extends AllPageProps {
  ppm365Updates: Ppm365Update[];
  partnerData: LandingPagePartner[]
}

export default function Ppm365LocationPage(props: ComponentProps):JSX.Element {
  const [globalState, setGlobalState] = useState<GlobalState>({ ...props.globalState, ppm365Updates: props.ppm365Updates});

  const [story, setStory] = useStoryblok(props.story, props.locale);

  return (
    <Layout locale={props.locale} locales={props.locales} defaultLocale={props.defaultLocale} globalState={globalState} setGlobalState={setGlobalState}>
      <NextSeo
        title={story.content.metatags?.title}
        description={story.content.metatags?.description}
      />
      <PartnerLandingContext.Provider value={props.partnerData}>
        <DynamicComponent blok={story.content} globalState={globalState} setGlobalState={setGlobalState} locale={props.locale}/>
      </PartnerLandingContext.Provider>
    </Layout>
  );
}

export async function getStaticProps({
  locale,
  locales,
  defaultLocale,
}) {
  try {
    // get storyblok data
    const sbParams = {
      ...defaultStoryblokOptions,
      language: locale,
    };

    const { data } = await Storyblok.get(`cdn/stories/usa/pages/365/`, sbParams);

    // get other page data from storyblok/ppmapi
    const allPageData = await getAllPageData(locale, defaultLocale);
    const ppm365Updates = await getPpm365Updates(locale, null);
    const partnerData = await getPartnerLandingData()

    
    return {
      props: {
        story: data ? data.story : false,
        globalState: { ...allPageData },
        ppm365Updates: ppm365Updates,
        locale,
        locales,
        defaultLocale,
        partnerData
      },
      revalidate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 3600 : 1, // rebuild in 1 hour for production,  1 second for staging
    };
  } catch (e) {
    return {
      notFound: true,
      revalidate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 3600 : 1, // rebuild in 1 hour for production,  1 second for staging
    };
  }
}
