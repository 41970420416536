import { SkeletonText } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { PartnerProfileContext } from "../../contexts/ppm365"
import Text from "../../layout/Text"

export interface PartnerHeaderProps {
    blok: any,
    globalState: GlobalState
}

const PartnerText = (props: PartnerHeaderProps): JSX.Element => {
    const profile = useContext(PartnerProfileContext)

    const [showText, setShow] = useState(false)

    useEffect(() => {
        setShow(
            (profile && profile.restrictedLocation !== 1) || 
            (props.globalState.user?.email?.length > 0) ||
            (!blok.hideIfRestricted && profile.restrictedLocation === 1)
        )
    }, [props.globalState.user?.email])

    const blok = props.blok

    if (blok.contentField === "pastorFullName") {
        blok.content = `${profile.pastorFirstName} ${profile.pastorLastName}`
    }
    else {
        blok.content = (profile as any)[blok.contentField]
    }

    return (
        <>
            { !showText &&
                <SkeletonText 
                    width={Number(blok.skeletonWidth)} 
                    height={`${blok.skeletonHeight}px`} 
                    style={{borderRadius: '0.5rem'}} 
                    noOfLines={Number(blok.skeletonLines)}
                    variant={'none'}>
                </SkeletonText>
            }
            { showText &&
                <Text blok={blok}></Text>
            }
        </>
    )
}

export default PartnerText