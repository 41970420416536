
import { useContext, useEffect, useState } from "react"
import { PartnerProfileContext, PartnerSupportStatusContext } from "../../contexts/ppm365"
import { DynamicComponentProps } from "../../DynamicComponent"
import Box from "../../layout/Box"


export interface PartnerSupportBoxProps extends DynamicComponentProps {

}

const PartnerSupportBox = ({ blok, globalState, setGlobalState, locationState, ppm365, locale }: PartnerSupportBoxProps): JSX.Element => {
    const supportStatus = useContext(PartnerSupportStatusContext)
    const profile = useContext(PartnerProfileContext)
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(
            (blok.visibility === "sponsors" && supportStatus && supportStatus.isSupporter) ||
            (blok.visibility === "nonSponsors" && supportStatus && supportStatus.isSupporter === false) ||
            (blok.visibility === "nonSponsors" && globalState && !globalState.user?.email)
        )
    }, [blok.visibility, globalState, globalState.user?.email, supportStatus])



    return (
        <>
            {
                show &&
                <Box blok={blok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} ppm365={ppm365} locale={locale}></Box>
            }
        </>
    )
}

export default PartnerSupportBox