import { Skeleton } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { PartnerProfileContext } from "../../contexts/ppm365"
import { DynamicComponentProps } from "../../DynamicComponent"
import RichText from "../../layout/RichText"

export interface PartnerRichTextProps extends DynamicComponentProps {

}

const PartnerRichText = ({blok, ...remainingProps}: DynamicComponentProps): JSX.Element => {

    const profile = useContext(PartnerProfileContext)

    const [showText, setShow] = useState(false)

    useEffect(() => {
        setShow(
            (profile && profile.restrictedLocation !== 1) ||
            (remainingProps.globalState.user?.email?.length > 0) ||
            (!blok.hideIfRestricted && profile.restrictedLocation === 1)
        )
    }, [remainingProps.globalState.user?.email])

    const textResolver = (text) => {
            return text.replaceAll("[pastorFullName]", `${profile.pastorFirstName} ${profile.pastorLastName}`)
            .replaceAll("[pastorFirstName]", profile.pastorFirstName)
            .replaceAll("[pastorTitle]", profile.pastorTitle)
            .replaceAll("[churchName]", profile.churchName)
            .replaceAll("[churchDescription]", profile.churchDescription)
            .replaceAll("[pastorDescription]", profile.pastorDescription)
            .replaceAll("[location]", profile.location)
            .replaceAll("[pastorLastName]", profile.pastorLastName)
    }

    return (
        <>
            { !showText &&
                <Skeleton 
                    width={Number(blok.skeletonWidth)} 
                    height={`${blok.skeletonHeight}px`} 
                    noOfLines={Number(blok.skeletonLines)}
                    style={{borderRadius: '0.5rem'}}>
                </Skeleton>
            }
            { showText &&
                <RichText blok={blok} textResolver={textResolver} {...remainingProps}></RichText>
            }
        </>
    )
}

export default PartnerRichText