import { Box } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"
import { useContext } from "react"
import { createMapData } from "../../../utils/maps"
import { PartnerLandingContext } from "../../contexts/ppm365"
import PpmMap from "../../maps/PpmMap"
import SinglePartnerInfoWindow from "../SinglePartnerInfoWindow"


export interface PartnerLandingMapProps {
    blok: any;
    width: number;
    height: number
}

const PartnerLandingMap = ({blok, width = 100, height = 500}: PartnerLandingMapProps): JSX.Element => {

  const partnerData =  useContext(PartnerLandingContext)

  const mapData = createMapData(partnerData.map(p => {return {...p, name: p.pastorFirstName + ' ' + p.pastorLastName}}))

    return (
        <Box
        {...storyblokEditable(blok)}
        minWidth={'100vw'}
         width={`${width}%`} height={height}>
        <PpmMap<LandingPagePartner> mapData={mapData} allowMarkerClick={true} SingleInfoWindow={SinglePartnerInfoWindow} MultiInfoWindow={()=> <></>}/>
        </Box>
    )
}

export default PartnerLandingMap