import { Box, Skeleton } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"
import { useContext, useEffect, useState } from "react"
import { createMapData } from "../../../utils/maps"
import { PartnerProfileContext } from "../../contexts/ppm365"
import PpmMap from "../../maps/PpmMap"

export interface PartnerMapProps {
    blok: any,
    globalState: GlobalState
}

const PartnerMap = (props: PartnerMapProps): JSX.Element => {

    let profile = useContext(PartnerProfileContext)
    let mapData = createMapData([{ ...profile, name: `` }])

    const [showMap, setShow] = useState(false)

    useEffect(() => {
        setShow(
            (profile && profile.restrictedLocation !== 1) || 
            (props.globalState.user?.email?.length > 0)
        )
        
        if (!props.blok.height && showMap) props.blok.height="100%"

        if (showMap) {
            mapData = createMapData([{ ...profile, name: `` }])
        }
        
    }, [props.globalState.user?.email, profile, profile.lat, profile.lng])

    return (
        <>
            { !showMap &&
                <Skeleton width={380} height={340} style={{borderRadius: '1rem'}}></Skeleton>
            }
            { showMap && 
                <Box
                    {...storyblokEditable(props.blok)}
                    borderRadius={"xl"}
                    overflow={"hidden"}
                    width={`${props.blok.width}%`} minHeight={"250px"} height={props.blok.height}>
                    <PpmMap<Ppm365PartnerProfile> allowMarkerClick={false} mapData={mapData} />
                </Box>
            }
        </>

    )
}

export default PartnerMap