import Image from "next/image"
import { useContext, useEffect, useState } from "react"
import { PartnerProfileContext, PartnerSupportStatusContext } from "../../contexts/ppm365"
import { storyblokEditable } from "@storyblok/react";
import { Skeleton } from "@chakra-ui/react";
export interface PartnerPhotoProps {
    blok: any,
    globalState: GlobalState
}

const PartnerPhoto = (props: PartnerPhotoProps): JSX.Element => {

    const profile = useContext(PartnerProfileContext)

    const blok = props.blok

    if (blok.priority === undefined) {
        blok.priority = false
    }

    let photo = ""

    switch (blok.photoType) {
        case "church":
            photo = profile.churchPhoto
            break
        case "pastor":
        default:
            photo = profile.pastorPhoto
            break;
    }

    const [showPhoto, setShow] = useState(false)
    const isSignedIn = props.globalState.user?.email?.length > 0;

    useEffect(() => {
        setShow(
            (profile && profile.restrictedLocation !== 1) || 
            (isSignedIn) ||
            (!blok.hideIfRestricted && profile.restrictedLocation === 1)
        )
    }, [props.globalState.user?.email])

    return (
        <>
            { !showPhoto &&
                <Skeleton 
                    width={Number(blok.skeletonWidth)} 
                    height={Number(blok.skeletonHeight)} 
                    style={{borderRadius: '1rem'}}>
                </Skeleton>
            }
            { showPhoto &&
                <div className="rounded-img">
                <Image
                    {...storyblokEditable(blok)}
                    src={photo ? photo : '/images/praying-pelican-logo-globe.png'}
                    alt={`Photo of ${profile.pastorFirstName} ${profile.pastorLastName}`}
                    style={{objectFit: "contain"}}
                    width={Number(blok.width)}
                    height={Number(blok.height)}
                    priority={blok.priority}
                ></Image>
                </div>
            }
        </>
    )
}

export default PartnerPhoto