import { getLoopbackAuthHeader } from "./pelicanAccount"
import Stripe from "@stripe/stripe-js"


export const getPartnerLandingData = async (): Promise<LandingPagePartner[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/homepage/365/partners`)).json()
}

export const getPartnerSupportOptions = async (): Promise<Ppm365StripePrice[]> => {
  return await (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/homepage/365/partners/support-options`)).json()
}

export const getPartnerByNumericId = async (id: number, isSignedIn?: boolean): Promise<Ppm365PartnerProfile | number> => {
  let response;
  
  if (isSignedIn) {
    response = await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/homepage/365/partners/unrestricted/${id.toString()}`, {
    headers: {
      ...getLoopbackAuthHeader()
    }
    })
  } else {
    response = await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/homepage/365/partners/${id.toString()}`)
  }

  if (response.status === 200) {
    return await response.json()
  }
  else {
    return response.status
  }

}

export const createSubscription = async (params: PostSubscriptionBody): Promise<[CreateSubscriptionResult, CreateSubscriptionError]> => {
  let result: CreateSubscriptionResult = { clientSecret: "", subscriptionId: "" }
  let error: CreateSubscriptionError = "none"

  const response = (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/stripe/subscription`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      ...getLoopbackAuthHeader(),
      "Content-Type": "application/json"
    }
  }))

  if (response.ok) {
    result = await response.json()
  }
  else {
    if (response.status === 401) {
      error = "unauthorized"
    }
    else {
      error = "unknown"
    }
  }

  return [result, error]

}

export const getSupportStatus = async (partnerId: number): Promise<SupportStatus> => {
  const response = (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/homepage/365/partners/${partnerId.toString()}/support-status`, {
    headers: {
      ...getLoopbackAuthHeader(),
    }
  }
  ))


  return response.json()
}

export const getUpdates = async (partnerId: number): Promise<PartnerUpdate[]> => {
  const response = (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/homepage/365/partners/${partnerId.toString()}/subscriber-updates`, {
    headers: {
      ...getLoopbackAuthHeader(),
    }
  }))

  return response.json()
}

export const getUpdate = async (partnerId: number, updateId: number): Promise<PartnerUpdate> => {
  const response = (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/homepage/365/partners/${partnerId.toString()}/subscriber-updates/${updateId}`, {
    headers: {
      ...getLoopbackAuthHeader(),
    }
  }))

  return response.json()
}

export const updateCustomerRecord = async (params: { address: Stripe.Address }) => {
  const response = (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/stripe/update-customer`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      ...getLoopbackAuthHeader(),
      "Content-Type": "application/json"
    }
  }
  ))

  if (!response.ok) {
    const text = await response.text
    throw new Error("Failed to update customer record: " + text )
  }

}